

.styled-button-spooky {
    background-color: #5e687e;
    color: #ffffff; 
    font-family: 'Creepster'; 
    font-size: 30px;
    padding: 15px 30px;
    /* border: 2px solid #4B0082; */
    border-radius: 5px;
    /* text-shadow: 2px 2px 4px #000000;  */
    transition: all 0.3s ease;
    cursor: pointer;
    margin-right: 15px;
}

.styled-button-spooky:hover {
    background-color: #545c6d; /* Lighter color on hover */
    color: #ffffff; 
    transform: scale(1.1); /* Slightly enlarges the button on hover */
}

.styled-button-special-spooky {
    background-color: #697692;
    color: #ffffff; 
    font-family: 'Creepster'; 
    font-size: 20px;
    padding: 10px 20px;
    /* border: 2px solid #4B0082; */
    border-radius: 5px;
    /* text-shadow: 2px 2px 4px #000000;  */
    transition: all 0.3s ease;
    cursor: pointer;
    margin-right: 15px;
}

.styled-button-special-spooky:hover {
    background-color: #8595b6; /* Lighter color on hover */
    color: #ffffff; 
    transform: scale(1.1); /* Slightly enlarges the button on hover */
}